import React, { useState, useEffect, useRef } from "react";
 
import Page1 from "../../../assets/images/OurVision.jpg";
import { motion, useAnimation, useInView } from 'framer-motion';
 
 
  

const AboutCard2 = () => {
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);
    return (
        <div className="w-full h-full bg-[#f4f4f4] p-b-m-15">
        <div className="container  ">
            <div className="pt-6 mpt-4 md-bg-white ">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                <motion.div
                            className=" "
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 8}}
                            transition={{
                                duration: 4,
                                ease: "easeInOut",
                            }}
                            ref={ref}
                        >
                    <div className="flex justify-center lg:justify-start">
                        <img src={Page1} alt="Left Arrow" className="w-full md:w-auto rounded-lg" />
                    </div> 
                    </motion.div>

                    <div className="bg-white text-start rounded-lg p-10 p-10-mob vision-mission-padding">
                    <motion.div
                            className=" "
                            initial={{ opacity: 0, y: 50 }}
                            animate={controls}
                            transition={{ duration: 2 }}
                            ref={ref}
                        >
                        <p className="text-[#222] text-4xl   font-semibold leading-normal">Our Vision</p>
                        <p className="text-[#58585A] pt-4  md:pt-8 mob-pt-4 about-text leading-loose visiontext-padding">
                            To develop advanced clinical pharmacist roles and responsibilities, to integrate with 
                            multi-professional teams for optimizing pharmacotherapy in the prevention and treatment of disease.
                        </p>
                        </motion.div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
    
    );
};

export default AboutCard2;
