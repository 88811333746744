import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from "react-router-dom"
import log1 from "../../../assets/images/fcpi 1.png";
import log2 from "../../../assets/images/menu-02.svg";
import dash from "../../../assets/images/dashboard-circleblack.svg";
import log3 from "../../../assets/images/dashboard-circle.png";
import "./Asidebar.css";
import dash1 from "../../../assets/images/forum.svg";
import dash11 from "../../../assets/images/forum-white.svg";
import dash111 from "../../../assets/images/members.svg";
import dashfm from "../../../assets/images/forum-memebres.svg";
import newsletrw from "../../../assets/images/newlwtter-white.svg";
import newsletr from "../../../assets/images/newsltter.svg";
import podw from "../../../assets/images/podcast-white.svg";
import pod from "../../../assets/images/podcast.svg"; 
import dashfm1 from "../../../assets/images/forum-mem-white.svg";
import user from "../../../assets/images/users.svg";
import userw from "../../../assets/images/users-white.svg";
import blogw from "../../../assets/images/blog-white.svg";
import blog from "../../../assets/images/blogs.svg";
import ccmsw from "../../../assets/images/cms-white.svg";
import cms from "../../../assets/images/cms.svg";
import enr from "../../../assets/images/enr-user.svg";
import enrw from "../../../assets/images/enr-w-user.svg";
import feed from "../../../assets/images/feed-user.svg";
import feedw  from "../../../assets/images/feed-w-user.svg";
import gencertw from "../../../assets/images/gen-cet-w.svg";
import  gencert from "../../../assets/images/gen-cert.svg";
import fbw from "../../../assets/images/forum-blog-w.svg";
import  fb from "../../../assets/images/forum-blog.svg";
import gbw from "../../../assets/images/gen-blog-w.svg";
import  gb from "../../../assets/images/gen-blog.svg";
import forc from "../../../assets/images/form-cert.svg";
import  formcertw  from "../../../assets/images/form-cert-w.svg";
 
import forume from "../../../assets/images/forum-ev.svg";
import forumew from "../../../assets/images/forum-w.svg";
 
import cert from "../../../assets/images/certificates.svg";
import certw from "../../../assets/images/certw.svg";
import bd from "../../../assets/images/board-b.svg";
import bdw from "../../../assets/images/board-w.svg";
import  gen from "../../../assets/images/gen-ev-b.svg";
import genw from "../../../assets/images/gen-ev-w.svg";

import dash2 from "../../../assets/images/speakers.svg";
import log5 from "../../../assets/images/calendar-02white.svg";
import dash3 from "../../../assets/images/eventsb.png";
import hm from "../../../assets/images/home-b.svg";
import hmw from "../../../assets/images/home-b-w.svg";
 
import  ns from "../../../assets/images/news.svg";
import  nsw from "../../../assets/images/news-w.svg";

import dash5 from "../../../assets/images/userw.png";
import log8 from "../../../assets/images/userb.png";
import dash6 from "../../../assets/images/logout-02black.svg";
import log9 from "../../../assets/images/logout-02whaite.svg";
import dash114 from "../../../assets/images/speaker-white.svg";
import dash12 from "../../../assets/images/member-white.svg";
import  gy from "../../../assets/images/gallery.svg";
import gym from "../../../assets/images/gallery-w.svg";
import { useNavigate } from 'react-router-dom';
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
import { BASE_URL } from '../../../Utils/Config';
import './Asidebar.css';
// 
const Asidebar = () => {
    const [hoveredButton, setHoveredButton] = useState(false);
    const [hovered1Button, setHovered1Button] = useState(false);
    const [hovered2Button, setHovered2Button] = useState(false);
    const [hovered3Button, setHovered3Button] = useState(false);
    const [hovered4Button, setHovered4Button] = useState(false);
    const [hovered5Button, setHovered5Button] = useState(false);
    const [hovered6Button, setHovered6Button] = useState(false);
    const [hovered7Button, setHovered7Button] = useState(false);
    const [hovered8Button, setHovered8Button] = useState(false);
    const [hovered9Button, setHovered9Button] = useState(false);
    const [hovered10Button, setHovered10Button] = useState(false);
    const [hovered11Button, setHovered11Button] = useState(false);
    const [hovered12Button, setHovered12Button] = useState(false);
    const [hovered13Button, setHovered13Button] = useState(false);
    const [hovered14Button, setHovered14Button] = useState(false);
    const [hovered15Button, setHovered15Button] = useState(false);
    const [hovered16Button, setHovered16Button] = useState(false);
    const [hovered17Button, setHovered17Button] = useState(false);
    const [hovered18Button, setHovered18Button] = useState(false);
    const [hovered19Button, setHovered19Button] = useState(false);
    const [hovered20Button, setHovered20Button] = useState(false);
    const [hovered21Button, setHovered21Button] = useState(false);
    const [hovered22Button, setHovered22Button] = useState(false);
    const [hovered23Button, setHovered23Button] = useState(false);
    const [hovered24Button, setHovered24Button] = useState(false);
    const [hovered25Button, setHovered25Button] = useState(false);
    const [hoveredAddButton, setHoveredAddButton] = useState(false);
    const [hoveredManageButton, setHoveredManageButton] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpenEvent, setDropdownOpenEvent] = useState(false);
    const [dropdownOpenblog, setDropdownOpenblog] = useState(false);
    const [dropdownOpenevent, setDropdownOpenevent] = useState(false);
    const [activeContent, setActiveContent] = useState(location.pathname);
    const [dropdowncertificateOpenevent, setDropdowncertificateOpenevent] = useState(false);
    const [dropdowncertificateOpenEvent, setDropdowncertificateOpenEvent] = useState(false);
    const [dropdownCertificateOpen, setDropdownCertificateOpen] = useState(false);



    useEffect(() => {
        const cmsPaths = ['/banner', '/gallerys', '/news', '/board', '/boardmembers', '/newcms'];
        const certPaths = ['/certificates', '/general-certificate'];

        setDropdownOpen(cmsPaths.includes(location.pathname));
        setDropdownCertificateOpen(certPaths.includes(location.pathname));
    }, [location.pathname]);



    useEffect(() => {

        const blogPaths = ['/ablogs', '/general-blog'];
        if (blogPaths.includes(location.pathname)) {
            setDropdownOpenblog(true);
        } else {
            setDropdownOpenblog(false);
        }
    }, [location.pathname]);

    const handleDropdownToggle = () => {
        setDropdownCertificateOpen(!dropdownCertificateOpen);
    };
    useEffect(() => {

        const eventPaths = ['/aevents', '/general-event','/enroll-users','/feedback-users'];
        if (eventPaths.includes(location.pathname)) {
            setDropdownOpenevent(true);
        } else {
            setDropdownOpenevent(false);
        }
    }, [location.pathname]);

    const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(true);
    const handleLogout = () => {
        localStorage.removeItem('adminAuthToken');
        localStorage.removeItem('logoutTime');
        navigate('/admin');
    };





    return (

        <div className='bg-gray-100'>
            <div className='bg-white p-6  '>

                <div className='flex justify-between'>
                    <img src={log1} alt="" />
                    <img src={log2} alt="" />
                </div>
                <div className='pt-8'>
                    <Link to="/admin-dashboard">
                        <button
                            className={`button w-full py-3 rounded-[6px] ${location.pathname === '/admin-dashboard' ? 'active' : ''}`}
                            onMouseEnter={() => setHoveredButton(true)}
                            onMouseLeave={() => setHoveredButton(false)}
                        >
                            <div className='flex gap-4 items-center pl-4 '>
                                <img src={hoveredButton  || location.pathname === '/admin-dashboard' ? log3 : dash} alt="Logo" className='dashboard-image' />
                                <p className='button-text text-[18px]'>
                                    Dashboard
                                </p>
                            </div>
                        </button>
      
                    </Link>
                    <div className='pt-1'>
                        <Link to="/aforum">
                            <button
                                className={`button  w-full py-3 rounded-[6px] ${location.pathname === '/aforum' ? 'active' : ''}`}
                                onMouseEnter={() => setHovered1Button(true)}
                                onMouseLeave={() => setHovered1Button(false)}
                            >
                                <div className='flex gap-4 items-center pl-4 '>

                                    <img src={hovered1Button  || location.pathname === '/aforum' ? dash11 : dash1} alt="Logo" className='dashboard-image' />
                                    <p className='button-text text-[18px]'>
                                        Forum
                                    </p>
                                </div>
                            </button>
                        </Link>
                    </div>
                    <div className='pt-1'>
                        <Link to="/member">
                            <button
                                className={`button  w-full py-3 rounded-[6px] ${location.pathname === '/member' ? 'active' : ''}`}
                                onMouseEnter={() => setHovered2Button(true)}
                                onMouseLeave={() => setHovered2Button(false)}
                            >
                                <div className='flex gap-4 items-center pl-4 '>

                                    <img src={ hovered2Button || location.pathname === '/member' ? dash12 : dash111} alt="Logo" className='dashboard-image' />
                                    <p className='button-text text-[18px]'>
                                        Member
                                    </p>
                                </div>
                            </button>
                        </Link>
                    </div>
                    <div className='pt-1'>
                        <Link to="/forummember">
                            <button
                                className={`button  w-full py-3 rounded-[6px] ${location.pathname === '/forummember' ? 'active' : ''}`}
                                onMouseEnter={() => setHovered3Button(true)}
                                onMouseLeave={() => setHovered3Button(false)}
                            >
                                <div className='flex gap-4 items-center pl-4 '>

                                    <img src={ hovered3Button || location.pathname === '/forummember' ? dashfm1 : dashfm} alt="Logo" className='dashboard-image' />
                                    <p className='button-text text-[18px]'>
                                        Forum Member
                                    </p>
                                </div>
                            </button>
                        </Link>
                    </div>
                    <div className='pt-1'>
                        <Link to="/speaker">
                            <button
                                className={`button  w-full py-3 rounded-[6px] ${location.pathname === '/speaker' ? 'active' : ''}`}
                                onMouseEnter={() => setHovered4Button(true)}
                                onMouseLeave={() => setHovered4Button(false)}
                            >
                                <div className='flex gap-4 items-center pl-4 '>

                                    <img src={ hovered4Button || location.pathname === '/speaker' ? dash114 : dash2} alt="Logo" className='dashboard-image' />
                                    <p className='button-text text-[18px]'>
                                        Speakers
                                    </p>
                                </div>
                            </button>
                        </Link>
                    </div>
                    <div className='pt-1'>
                        {/* <Link to="/aevents"> */}
                        <button
                            className={`button  w-full py-3 rounded-[6px] ${location.pathname === '' ? 'active' : ''}`}
                            onClick={() => setDropdownOpenevent(!dropdownOpenEvent)}
                            onMouseEnter={() => setHovered5Button(true)}
                            onMouseLeave={() => setHovered5Button(false)}
                        >
                            <div className='flex gap-4 items-center pl-4 '>

                                <img src={ hovered5Button || location.pathname === ' ' ? log5 : dash3} alt="Logo" className='dashboard-image' />
                                <p className='button-text text-[18px]'>
                                    Events
                                </p>
                            </div>
                        </button>
                        {dropdownOpenevent && (
                            <div className="dropdowncms-menu">

                                <Link to="/aevents">
                                    <div className='pl-9'>
                                        <button
                                            className={`button w-full py-3 rounded-[6px] ${activeContent === '/aevents' ? 'active' : ''}`}
                                            onMouseEnter={() => setHovered6Button(true)}
                                            onMouseLeave={() => setHovered6Button(false)}

                                        >
                                            <div className='flex gap-4 items-center pl-4'>
                                                <img src={ hovered6Button || activeContent === '/aevents' ?  forumew : forume} alt="Banner" className='dashboard-image' />
                                                <p className='button-text text-[18px]'>Forum Event</p>
                                            </div>
                                        </button>
                                    </div>
                                </Link>
                                <Link to="/general-event">
                                    <div className='pl-9'>
                                        <button
                                            className={`button w-full py-3 rounded-[6px] ${location.pathname === '/general-event' ? 'active' : ''}`}
                                            onMouseEnter={() => setHovered7Button(true)}
                                            onMouseLeave={() => setHovered7Button(false)}
                                        >
                                            <div className='flex gap-4 items-center pl-4 '>

                                                <img src={ hovered7Button ||location.pathname === '/general-event' ? genw : gen} alt="Logo" className='dashboard-image' />
                                                <p className='button-text text-[18px]'>
                                                    General Event
                                                </p>
                                            </div>
                                        </button>
                                    </div>
                                </Link>
                                <Link to="/enroll-users">
                                    <div className='pl-9'>
                                        <button
                                            className={`button w-full py-3 rounded-[6px] ${location.pathname === '/enroll-users' ? 'active' : ''}`}
                                            onMouseEnter={() => setHovered8Button(true)}
                                            onMouseLeave={() => setHovered8Button(false)}
                                        >
                                            <div className='flex gap-4 items-center pl-4 '>

                                                <img src={hovered8Button ||location.pathname === '/enroll-users' ? enrw : enr} alt="Logo" className='dashboard-image' />
                                                <p className='button-text text-[18px]'>
                                                    Enrolled Users
                                                </p>
                                            </div>
                                        </button>
                                    </div>
                                </Link>

                                <Link to="/feedback-users">
                                    <div className='pl-9'>
                                        <button
                                            className={`button w-full py-3 rounded-[6px] ${location.pathname === '/feedback-users' ? 'active' : ''}`}
                                            onMouseEnter={() => setHovered9Button(true)}
                                            onMouseLeave={() => setHovered9Button(false)}
                                        >
                                            <div className='flex gap-4 items-center pl-4 '>

                                                <img src={hovered9Button || location.pathname === '/feedback-users' ?  feedw :  feed} alt="Logo" className='dashboard-image' />
                                                <p className='button-text text-[18px]'>
                                                     Feedback Users
                                                </p>
                                            </div>
                                        </button>
                                    </div>
                                </Link>
                            </div>
                        )}
                  
                    </div>


                    

                 
                    <div className='pt-1'>
                     
                        <button
                            className={`button w-full py-3 rounded-[6px] ${dropdownCertificateOpen ? 'active' : ''}`}
                            onMouseEnter={() => setHovered10Button(true)}
                            onMouseLeave={() => setHovered10Button(false)}
                            onClick={handleDropdownToggle}
                        >
                            <div className='flex gap-4 items-center pl-4'>
                                <img src={ hovered10Button || dropdownCertificateOpen ? certw :  cert} alt="Logo" className='dashboard-image' />
                                <p className='button-text text-[18px]'>Certificates</p>
                            </div>
                        </button>
                        {dropdownCertificateOpen && (
                            <div className="dropdowncms-menu">

                                <Link to="/certificates">
                                    <div className='pl-9'>
                                        <button
                                            className={`button w-full py-3 rounded-[6px] ${activeContent === '/certificates' ? 'active' : ''}`}
                                            onMouseEnter={() => setHovered11Button(true)}
                                            onMouseLeave={() => setHovered11Button(false)}
                                        >
                                            <div className='flex gap-4 items-center pl-4'>
                                                <img src={hovered11Button || activeContent === '/certificates' ?  formcertw : forc} alt="Banner" className='dashboard-image' />
                                                <p className='button-text text-[18px]'>Forum Certificates</p>
                                            </div>
                                        </button>
                                    </div>
                                </Link>
                                <Link to="/general-certificate">
                                    <div className='pl-9'>
                                        <button
                                            className={`button w-full py-3 rounded-[6px] ${location.pathname === '/general-certificate' ? 'active' : ''}`}
                                            onMouseEnter={() => setHovered12Button(true)}
                                            onMouseLeave={() => setHovered12Button(false)}
                                        >
                                            <div className='flex gap-4 items-center pl-4 '>

                                                <img src={ hovered12Button || location.pathname === '/general-certificate' ? gencertw :  gencert} alt="Logo" className='dashboard-image' />
                                                <p className='button-text text-[18px]'>
                                                    General  Certificates
                                                </p>
                                            </div>
                                        </button>
                                    </div>
                                </Link>



                            </div>
                        )}
                        {/* </Link> */}
                    </div>

                    {/* hhhhhhhhhhhhhhhhhhhhhhhhhhh */}
                    {/* <div className='pt-1'>
                        <Link to="/certificates">
                            <button
                                className={`button w-full py-3 rounded-[6px] ${location.pathname === '/certificates' ? 'active' : ''}`}
                            >
                                <div className='flex gap-4 items-center pl-4 '>

                                    <img src={location.pathname === '/certificates' ? log8 : dash5} alt="Logo" className='dashboard-image' />
                                    <p className='button-text text-[18px]'>
                                        Certificates
                                    </p>
                                </div>
                            </button>
                        </Link>
                    </div> */}
                    <div className='pt-1'>
                        <Link to="/user">
                            <button
                                className={`button w-full py-3 rounded-[6px] ${location.pathname === '/user' ? 'active' : ''}`}
                                onMouseEnter={() => setHovered13Button(true)}
                                onMouseLeave={() => setHovered13Button(false)}
                            >
                                <div className='flex gap-4 items-center pl-4 '>

                                    <img src={hovered13Button || location.pathname === '/user' ?  userw :  user} alt="Logo" className='dashboard-image' />
                                    <p className='button-text text-[18px]'>
                                        Users
                                    </p>
                                </div>
                            </button>
                        </Link>
                    </div>
                    <div className='pt-1'>
                        <Link to="/seconduser">
                            <button
                                className={`button w-full py-3 rounded-[6px] ${location.pathname === '/seconduser' ? 'active' : ''}`}
                                onMouseEnter={() => setHovered14Button(true)}
                                onMouseLeave={() => setHovered14Button(false)}
                            >
                                <div className='flex gap-4 items-center pl-4 '>

                                    <img src={hovered14Button || location.pathname === '/seconduser' ? log8 : dash5} alt="Logo" className='dashboard-image' />
                                    <p className='button-text text-[18px]'>
                                        Chat Users
                                    </p>
                                </div>
                            </button>
                        </Link>
                    </div>


                    <div className='pt-1'>
                        <button
                            className={`button w-full py-3 rounded-[6px] ${location.pathname === '' ? 'active' : ''}`}
                            onMouseEnter={() => setHovered15Button(true)}
                            onMouseLeave={() => setHovered15Button(false)}
                            onClick={() => setDropdownOpenblog(!dropdownOpen)}
                        >
                            <div className='flex gap-4 items-center pl-4'>
                                <img src={hovered15Button ||location.pathname === '' ? blogw : blog} alt="CMS Logo" className='dashboard-image' />
                                <p className='button-text text-[18px]'>Blogs</p>
                            </div>
                        </button>
                        {dropdownOpenblog && (
                            <div className="dropdowncms-menu">

                                <Link to="/ablogs">
                                    <div className='pl-9'>
                                        <button
                                            className={`button w-full py-3 rounded-[6px] ${activeContent === '/ablogs' ? 'active' : ''}`}
                                            onMouseEnter={() => setHovered16Button(true)}
                                            onMouseLeave={() => setHovered16Button(false)}

                                        >
                                            <div className='flex gap-4 items-center pl-4'>
                                                <img src={hovered16Button ||activeContent === '/ablogs' ? fbw :  fb} alt="Banner" className='dashboard-image' />
                                                <p className='button-text text-[18px]'>Forum Blog</p>
                                            </div>
                                        </button>
                                    </div>
                                </Link>
                                <Link to="/general-blog">
                                    <div className='pl-9'>
                                        <button
                                            className={`button w-full py-3 rounded-[6px] ${location.pathname === '/general-blog' ? 'active' : ''}`}
                                            onMouseEnter={() => setHovered17Button(true)}
                                            onMouseLeave={() => setHovered17Button(false)}
                                        >
                                            <div className='flex gap-4 items-center pl-4 '>

                                                <img src={hovered17Button || location.pathname === '/general-blog' ? gbw : gb} alt="Logo" className='dashboard-image' />
                                                <p className='button-text text-[18px]'>
                                                    General Blog
                                                </p>
                                            </div>
                                        </button>
                                    </div>
                                </Link>



                            </div>
                        )}


                    </div>

                    <div className='pt-1'>

                        <button
                            className={`button w-full py-3 rounded-[6px] ${location.pathname === '' ? 'active' : ''}`}
                            onMouseEnter={() => setHovered18Button(true)}
                            onMouseLeave={() => setHovered18Button(false)}
                            onClick={() => setDropdownOpen(!dropdownOpen)}
                        >
                            <div className='flex gap-4 items-center pl-4'>
                                <img src={hovered18Button ||location.pathname === '' ? ccmsw : cms} alt="CMS Logo" className='dashboard-image' />
                                <p className='button-text text-[18px]'>CMS</p>
                            </div>
                        </button>


                        {dropdownOpen && (
                            <div className="dropdowncms-menu">

                                <Link to="/banner">
                                    <div className='pl-9'>
                                        <button
                                            className={`button w-full py-3 rounded-[6px] ${activeContent === '/banner' ? 'active' : ''}`}
                                            onMouseEnter={() => setHovered19Button(true)}
                                            onMouseLeave={() => setHovered19Button(false)}

                                        >
                                            <div className='flex gap-4 items-center pl-4'>
                                                <img src={hovered19Button || activeContent === '/banner' ?  hmw : hm} alt="Banner" className='dashboard-image' />
                                                <p className='button-text text-[18px]'>Home Banner</p>
                                            </div>
                                        </button>
                                    </div>
                                </Link>
                                <Link to="/gallerys">
                                    <div className='pl-9'>
                                        <button
                                            className={`button w-full py-3 rounded-[6px] ${location.pathname === '/gallerys' ? 'active' : ''}`}
                                            onMouseEnter={() => setHovered20Button(true)}
                                            onMouseLeave={() => setHovered20Button(false)}
                                        >
                                            <div className='flex gap-4 items-center pl-4 '>

                                                <img src={hovered20Button || location.pathname === '/gallerys' ? gym : gy} alt="Logo" className='dashboard-image' />
                                                <p className='button-text text-[18px]'>
                                                    Gallery
                                                </p>
                                            </div>
                                        </button>
                                    </div>
                                </Link>
                                <Link to="/news">
                                    <div className='pl-9'>
                                        <button
                                            className={`button w-full py-3 rounded-[6px] ${location.pathname === '/news' ? 'active' : ''}`}
                                            onMouseEnter={() => setHovered21Button(true)}
                                            onMouseLeave={() => setHovered21Button(false)}

                                        >
                                            <div className='flex gap-4 items-center pl-4 '>

                                                <img src={hovered21Button || location.pathname === '/news' ? nsw :  ns} alt="Logo" className='dashboard-image' />
                                                <p className='button-text text-[18px]'>
                                                    News
                                                </p>
                                            </div>
                                        </button>
                                    </div>
                                </Link>
                                {/* <Link to="/board">
                                    <div className='pl-9'>
                                        <button
                                            className={`button w-full py-3 rounded-[6px] ${location.pathname === '/board' ? 'active' : ''}`}
                                        >
                                            <div className='flex gap-4 items-center pl-4 '>

                                                <img src={location.pathname === '/board' ? log8 : dash5} alt="Logo" className='dashboard-image' />
                                                <p className='button-text text-[18px]'>
                                                    Board
                                                </p>
                                            </div>
                                        </button>
                                    </div>
                                </Link> */}
                                <Link to="/boardmembers">
                                    <div className='pl-9'>
                                        <button
                                            className={`button w-full py-3 rounded-[6px] ${location.pathname === '/boardmembers' ? 'active' : ''}`}
                                            onMouseEnter={() => setHovered22Button(true)}
                                            onMouseLeave={() => setHovered22Button(false)}
                                        >
                                            <div className='flex gap-4 items-center pl-4 '>

                                                <img src={hovered22Button || location.pathname === '/boardmembers' ? bdw :  bd} alt="Logo" className='dashboard-image' />
                                                <p className='button-text text-[18px]'>
                                                    Board Members
                                                </p>
                                            </div>
                                        </button>
                                    </div>
                                </Link>
                            </div>
                        )}
                    </div>
                    <div className='pt-1'>
                        <Link to="/newsletter">
                            <button
                                className={`button w-full py-3 rounded-[6px] ${location.pathname === '/newsletter' ? 'active' : ''}`}
                                onMouseEnter={() => setHovered23Button(true)}
                                onMouseLeave={() => setHovered23Button(false)}
                            >
                                <div className='flex gap-4 items-center pl-4 '>

                                    <img src={hovered23Button || location.pathname === '/newsletter' ? newsletrw : newsletr} alt="Logo" className='dashboard-image' />
                                    <p className='button-text text-[18px]'>
                                        Newsletter
                                    </p>
                                </div>
                            </button>
                        </Link>
                    </div>
                    <div className='pt-1'>
                        <Link to="/podcast">
                            <button
                                className={`button w-full py-3 rounded-[6px] ${location.pathname === '/podcast' ? 'active' : ''}`}
                                onMouseEnter={() => setHovered24Button(true)}
                                onMouseLeave={() => setHovered24Button(false)}
                            >
                                <div className='flex gap-4 items-center pl-4 '>

                                    <img src={ hovered24Button || location.pathname === '/podcast' ? podw : pod} alt="Logo" className='dashboard-image' />
                                    <p className='button-text text-[18px]'>
                                         Podcast
                                    </p>
                                </div>
                            </button>
                        </Link>
                    </div>
                     
              
                    <div className='pt-1'>
                        <button
                            className='button w-full py-3 rounded-[6px]'
                            onMouseEnter={() => setHovered7Button(true)}
                            onMouseLeave={() => setHovered7Button(false)}
                            onClick={handleLogout}
                        >
                            <div className='flex gap-4 items-center pl-4'>
                                <img src={hovered7Button ? log9 : dash6} alt="Logo" className='dashboard-image' />
                                <p className='button-text text-[18px]'>
                                    Logout
                                </p>
                            </div>
                        </button>
                    </div>
                </div>
                <div className='pl-4 pt-4'>
                    <p className='text-[#969696] text-[14px] not-italic font-normal leading-[16px] tracking-[0.6px]'>Powered by <br />
                        Hoztox Technologies</p>
                    <div className='pt-3'>
                        <a href="https://www.hoztox.com" className='text-[#F58A2F] text-[14px] not-italic font-normal leading-[24px] tracking-[0.6px] underline' target="_blank" rel="noopener noreferrer">www.hoztox.com</a>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Asidebar;
