import React, { useState, useEffect,useRef } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import foot from "../../../src/assets/images/fcpi 1.png";
import foot3 from "../../../src/assets/images/mail-at-sign-02.png";
import foot2 from "../../../src/assets/images/location-06.png";
import foot4 from "../../../src/assets/images/_Facebook.png";
import foot5 from "../../../src/assets/images/_Twitter.png";
import foot6 from "../../../src/assets/images/_Instagram.png";
import foot7 from "../../../src/assets/images/_Linkedin.png";
import foot8 from "../../../src/assets/images/_YouTube.png";
import logo20 from "../../../src/assets/images/arrow-right-02-round.png";
import './Footer.css';
import { motion, useAnimation, useInView } from 'framer-motion';


const Footer = () => {
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);

    return (
        <div className="w-full h-full bg-[#f4f4f4] pt-2">
            <div className="container ">
                <div className="bg-[#222] rounded-[30px] p-footer-all">
                    {/* <motion.div
                        className=""
                        initial={{ opacity: 0, y: 50 }}
                        animate={controls}
                        transition={{ duration: 3 }}
                        ref={ref}
                    > */}
                        <div className="relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-footer">
                            <div className="">
                                <div className="">
                                    <img src={foot} alt="Logo" />
                                </div>
                                <div className="flex items-center">
                                    <div className="location">
                                        <img src={foot2} alt="Logo" className="rounded-[30px]" />
                                    </div>
                                    <p className="text-start text-[#969696] pl-4 pt-6 text-[14px] not-italic font-normal leading-[24px]">
                                        No.677, 1st Floor, Suite # 845,27th <br />
                                        Main,13th Cross Road, Sector 1,HSR <br />
                                        Layout, Bangalore - 560102
                                    </p>
                                </div>
                                <div className="flex items-center mtop-20">
                                    <div className="location">
                                        <img src={foot3} alt="Logo" className="rounded-[30px]" />
                                    </div>
                                    <a target="_blank" href="mailto:fcpiofficial@gmail.com"> <p className="text-start text-[#969696] hover:text-[#fff] pl-4 text-[14px] not-italic font-normal leading-[24px]">
                                        fcpiofficial@gmail.com
                                    </p></a>
                                </div>
                            </div>
                            {/* <div className="text-[#C5C5C5] text-start footertop-padding footer-gap mob-gap-padding border-mob text-[26px] not-italic font-semibold leading-[normal] newsletter-padding hide-mobile-div" >
                                Subscribe for update<br /> and newsletter.
                                <div className="newsletter mt-6 relative">
                                    <input type className="m-width newsletter " placeholder="Enter your email address" />
                                    <button className="getbtn" >
                                        Get Started
                                    </button>
                                </div>

                            </div> */}

                            <div className="text-start footer-menu footer-gap mob-gap ">
                                <Link to="/"><p className="text-[#969696] text-[16px] m-text-16 not-italic  font-normal leading-[26px] hover:text-[#fff]">Home</p> </Link>
                                <Link to="/about-fcpi"> <p className="text-[#969696] text-[16px]  m-text-16 not-italic pt-3 font-normal leading-[26px] hover:text-[#fff]">About FCPI</p> </Link>
                                <Link to="/board-of-directors"> <p className="text-[#969696] text-[16px]  m-text-16 not-italic pt-3 font-normal leading-[26px] hover:text-[#fff]">Board of Directors</p></Link>
                                <Link to="/committees"><p className="text-[#969696] text-[16px]  m-text-16 not-italic pt-3 font-normal leading-[26px] hover:text-[#fff]">Committees</p></Link>
                                <Link to="/forum-members"> <p className="text-[#969696] text-[16px]  m-text-16 not-italic pt-3 font-normal leading-[26px] hover:text-[#fff]">Forum Members</p></Link>
                            </div>

                            <div className="text-start footer-menu footer-gap mob-gap1 ">
                                <Link to="/events"> <p className="text-[#969696] text-[16px]  m-text-16 not-italic pt-3 font-normal leading-[26px] hover:text-[#fff]">Events</p></Link>
                                <Link to="/blogs"><p className="text-[#969696] text-[16px]  m-text-16 not-italic pt-3 font-normal leading-[26px] hover:text-[#fff]">Blogs</p></Link>
                                <Link to="/podcasts"><p className="text-[#969696] text-[16px] m-text-16 not-italic pt-3 font-normal leading-[26px] hover:text-[#fff]">Podcasts</p> </Link>
                                <Link to="/gallery"> <p className="text-[#969696] text-[16px]  m-text-16 not-italic pt-3 font-normal leading-[26px] hover:text-[#fff]">Gallery</p> </Link>                              
                                <Link to="/contact"> <p className="text-[#969696] text-[16px]  m-text-16 not-italic pt-3 font-normal leading-[26px] hover:text-[#fff]">Contact Us</p></Link>
                            </div>
                            <div className="footer-socail ">
                                {/* <div className="social-head">
                                 <p className="text-[#969696] text-start text-[16px] not-italic font-normal leading-[26px]">Social media</p> 
                            </div> */}

                                <div className="social-icons">
                                    <p className="text-[#969696] text-start not-italic font-normal leading-[26px]">Social media</p>
                                    <div className="flex gap-1 icon-items">
                                        <a target="_blank" href="https://www.facebook.com/FCPIOfficialPage">
                                            <div className="location_footer">
                                                <img src={foot4} alt="Logo" />
                                            </div>
                                        </a>


                                        <a target="_blank" href="https://twitter.com/FCPIOfficial">
                                            <div className="location_footer">
                                                <img src={foot5} alt="Logo" />
                                            </div>
                                        </a>

                                        <a target="_blank" href="https://www.instagram.com/fcpiofficial/">
                                            <div className="location_footer">
                                                <img src={foot6} alt="Logo" />
                                            </div>
                                        </a>

                                        <a target="_blank" href="https://www.linkedin.com/company/federation-of-clinical-pharmacists-in-india-fcpi/?viewAsMember=true">
                                            <div className="location_footer">
                                                <img src={foot7} alt="Logo" />
                                            </div>
                                        </a>

                                        <a target="_blank" href="https://www.youtube.com/channel/UCRYLR2xnXSfhu2cHXVhYz4A">
                                            <div className="location_footer">
                                                <img src={foot8} alt="Logo" />
                                            </div>
                                        </a>

                                    </div>
                                </div>
                                {/* <div className="join-btn">
                            <div className="rounded-[105px] bg-[#1AB78D] py-[10px] px-[55px] flex items-center maxm-228">
                                <p className="text-[#FFF] text-[14px] not-italic font-normal leading-[normal] "
                                >Join FCPI</p>
                                <div className="ml-4">
                                    <img src={logo20} alt="Logo" />
                                </div>
                            </div>
                        </div> */}
                            </div>







                        </div>

                        <div className="footerbottom">
                            <a href="https://www.hoztox.com" className='hoztoz-color text-[14px] not-italic font-normal leading-[24px] no-underline tracking-[0.6px] underline' target="_blank" rel="noopener noreferrer">www.hoztox.com</a>
                        </div>
                        {/* </motion.div> */}
                </div>



            </div>
        </div>
    );
};

export default Footer;
