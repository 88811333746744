import React, { useState, useEffect, useRef } from "react";
import Page from "../../../assets/images/Maskgroup.jpg";
import Page1 from "../../../assets/images/Vector.svg";
import Page2 from "../../../assets/images/learn.png";
import { motion, useAnimation, useInView } from 'framer-motion';
import './About.css';


const About = () => {
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);
    return (
        <div class="w-full h-full bg-[#f4f4f4]">
            <div class="container  ">
                <div class="pt-6 p-b-m-15 ">
                    <div class="flex mflex bg-white rounded-lg p-8 aboutpadding   mob-mar-bt-20">
                        <motion.div
                            className="relative p-5 mp-5 md:w-100 lg:w-1/3 mob-hide-about-img mb-10 "
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 3}}
                            transition={{
                                duration: 4,
                                ease: "easeInOut",
                            }}
                            ref={ref}
                        >
                        
                            <img src={Page} alt="Left Arrow" class="w-full h-auto rounded-lg" />
                            
                            <div class="line-img transform -translate-y-1/2 z-20 ">
                                <img className="line-about" src={Page1} alt="Left Arrow" />
                            </div>
                            </motion.div>
                        <motion.div
                            className="md:w-100 lg:w-2/3 mob-w-100-abouttext p-abouttext"
                            initial={{ opacity: 0, y: 50 }}
                            animate={controls}
                            transition={{ duration: 2 }}
                            ref={ref}
                        >

                            <p class="text-[#222] text-[34px] font-bold leading-normal">About FCPI</p>
                            <p class="text-[#58585A] pt-8 mob-p-t-1 text-start about-text leading-8 md:leading-10">The Federation of Clinical Pharmacists in India (FCPI) is a non-profitable, professional pharmacy organization composed of a bunch of passionate pharmacists involved in diverse clinical pharmacy responsibilities in India and across the globe. We have come together voluntarily with the undeterred goal of enhancing the clinical pharmacy profession, empowering the clinical pharmacists and thereby delivering high-quality patient care.</p>


                        </motion.div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default About;
